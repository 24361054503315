<template>
  <div class="container">
    <h1 class="heading">Our Products</h1>
    <router-link class="quote" to="/contactus">REQUEST A QUOTE</router-link>
    <div class="products">
      <div class="product" v-for="d in data" :key="d.id">
        <img :src="require('@/assets/productslist/' + d.image)" alt="" />
        <h3>
          <router-link :to="d.route">{{ d.name }}</router-link>
        </h3>
        <p>{{ d.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { FetchData } from "@/data/products.js";
export default defineComponent({
  setup() {
    const { data, error, isLoading, run: getData } = FetchData();
    onMounted(async () => {
      await getData();
    });
    return { data, error, isLoading, getData };
  },
});
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
  .heading {
    text-align: center;
    font-size: 60px;
    font-weight: bolder;
    padding: 30px 0;
  }
  .products {
    display: grid;
    column-gap: 5px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    .product {
      img {
        height: 140px;
      }
      h3 {
        font-size: 40px;
        margin: auto;
        padding: 30px 0;
      }
      p {
        font-size: 30px;
        padding: 20px 0;
      }
    }
  }
}
.quote {
  background: #ff9c00;
  font-size: 20px;
  color: white;
  font-weight: bold;
  display: block;
  height: 40px;
  border: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
