import axios from 'axios';
import {
    useApi
} from './myapi';

export function FetchData() {
    return useApi(Products)
}
const Products = async () => {
    const response = await axios("data/products.json");
    return normalizeUsersReponse(response);
};

function normalizeUsersReponse(response) {
    return response.data;
    //   return response.data.map(normalizeUser);
}

// function normalizeUser(user) {
//     return {
//         id: user.id,
//         name: user.name,
//         description: user.description,
//         image: user.image,
//         active: user.active,
//         // image: user.images.main || "/assets/placeholder.png",
//     };
// }

// function serializeUser(user) {
//     return {
//         id: user.id,
//         name: user.name,
//         description: user.description,
//         active: user.active,
//     };
// }